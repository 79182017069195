import { Col, Row } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoComplete } from '~components';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { useAppSelector } from '~hooks';

type Props = {
    control: any;
    disabled?: boolean;
};
function CardHeader(props: Props) {
    const { control, disabled } = props;
    const { t } = useTranslation();

    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);

    const guestOptions = useMemo(() => {
        return selectedRoomBookingDetail?.guests?.map((guest) => {
            return {
                label: guest.yomigana,
                value: guest.yomigana,
            };
        });
    }, [selectedRoomBookingDetail]);

    return (
        <div className="guest-select-booking-card">
            <Row className="guest-name-row">
                <Col span={24}>
                    <AutoComplete
                        label=""
                        placeholder={t(
                            'roomBooking.detail.bookingItemCard.guestYomigana.placeholder',
                        )}
                        name="representativeGuestName"
                        required
                        allowClear
                        control={control}
                        options={guestOptions}
                        disabled={disabled}
                    />
                </Col>
            </Row>
        </div>
    );
}
export default CardHeader;
