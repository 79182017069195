import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Divider, Form, notification } from 'antd';
import { debounce, forEach } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageType, UserGroup } from '~common/constants';
import { showConfirm } from '~common/notification';
import { childrenStateSelector } from '~features/children-type/reducers/children.reducer';
import { roomBookingService } from '~features/room-booking/services/room-booking.service';
import { generateUpdateBookingData } from '~features/room-booking/util';
import {
    ChangingBookingStatusesMap,
    RoomBookingItemBookingStatus,
} from '~features/room-booking/constants';
import {
    validateDayUseTimeIn24H,
    validateGenderBreakdownOfGuest,
    validateStayingDate,
} from '~features/room-booking/helper';
import { isChangeReceiptPrice } from '~features/room-booking/helper.update-booking';
import {
    IChildBookingAmounts,
    IGetBookingPrice,
    IRoomBookingItem,
    IUpdateRoomBookingItem,
    IUpdateRoomBookingItemBody,
} from '~features/room-booking/interfaces';
import {
    createBookingStateSelector,
    getBookingPrice,
} from '~features/room-booking/reducers/create-booking.reducer';
import {
    isUpdatingBookingSelector,
    updateRoomBookingItem,
} from '~features/room-booking/reducers/room-booking.reducer';
import { updateRoomBookingItemSchema } from '~features/room-booking/schema';
import { IRoomType } from '~features/room-type/interfaces';
import { roomTypeListDropdownSelector } from '~features/room-type/room-type.reducer';
import { getListForDropDown } from '~features/room/room.reducer';
import { useAppDispatch, useAppSelector } from '~hooks';
import { Dayjs, parseDate, parseTime } from '~plugins/dayjs';
import { useForm } from '~plugins/hook-form';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';
import CardHeader from './CardHeader/CardHeader';
import CardContent from './RoomBookingItemContent/CardContent';
import { checkUserPermission } from '~common/commonFunctions';
import { checkHasOverlappingDatesInRoomBookingItems } from '~common/helper';

type Props = {
    bookingItem: IRoomBookingItem;
    isFromTll?: boolean;
    isSingleBooking?: boolean;
    isFrozen?: boolean;
    closePopover: (visible: boolean, isReload: boolean) => void;
    setShowConfirm: (visible: boolean) => void;
    onUpdateSuccess?: (booking: IRoomBookingItem) => void;
    onUpdateStatusSuccess?: (booking: IRoomBookingItem) => void;
};

function RoomBookingItemForm(props: Props) {
    const {
        bookingItem,
        isFromTll,
        isSingleBooking,
        isFrozen,
        closePopover,
        setShowConfirm,
        onUpdateSuccess,
        onUpdateStatusSuccess,
    } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isAdmin = useMemo(() => {
        return checkUserPermission([UserGroup.ADMIN]);
    }, []);
    const formBusy = useAppSelector(isUpdatingBookingSelector);
    const roomTypeDropdown = useAppSelector(roomTypeListDropdownSelector);
    const { children } = useAppSelector(childrenStateSelector);
    const { isCheckingPlan } = useAppSelector(createBookingStateSelector);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);

    const { control, reset, handleSubmit, setError, setValue, getValues } = useForm({
        resolver: yupResolver(updateRoomBookingItemSchema),
    });
    const [childrenList, setChildrenList] = useState<Record<string, number>>();

    const _getBookingPrice = async () => {
        const roomTypeId = getValues('roomTypeId');
        const planId = getValues('planId');
        const numberOfAdults = getValues('numberOfAdults');
        const [start, end] = getValues('dateOfStayPeriod') as [Dayjs, Dayjs];
        if (!roomTypeId || !planId || !numberOfAdults || !start || !end) return false;

        const query: IGetBookingPrice = {
            roomTypeId: roomTypeId,
            startDateOfStay: start.fmYYYYMMDD(),
            endDateOfStay: end.fmYYYYMMDD(),
            numberOfAdults: Number(numberOfAdults),
            planId: planId,
            childrenTypeQuantities: children.map((item) => ({
                childrenTypeId: item.id,
                quantity: childrenList?.['id_' + item.id] || 0,
            })),
        };
        const response = await dispatch(getBookingPrice(query));
        if (getBookingPrice.fulfilled.match(response)) {
            if (!response?.payload?.success) {
                notification.error({
                    message:
                        response?.payload?.message ||
                        t('roomBooking.createBooking.message.calculateAmountError'),
                });
                return false;
            }
            bookingPrice.current = response.payload?.data?.amount || 0;
            return true;
        }
        return true;
    };

    const isSubmitting = useRef(false);
    const bookingPrice = useRef(0);

    const checkPriceAndUpdateBooking = async (
        bookingItemBody: IUpdateRoomBookingItemBody,
    ) => {
        const check = await _getBookingPrice();
        if (!check) {
            setError('planId', {
                type: ErrorMessageType.MANUAL,
                message: t('roomBooking.form.planInvalidPrice'),
            });
            isSubmitting.current = false;
            return;
        }
        const {
            bookingStatus,
            roomId,
            roomTypeId,
            roomBookingItemChildrenTypes = [],
        } = bookingItemBody;
        if (bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED) {
            bookingItemBody.roomTypeId = roomId ? undefined : roomTypeId;
            bookingItemBody.roomBookingItemChildrenTypes =
                roomBookingItemChildrenTypes.map((item) => {
                    return {
                        ...item,
                        childrenTypeId: !item?.id ? item.childrenTypeId : undefined,
                    };
                });
        }
        _updateRoomBookingItem({
            id: Number(bookingItem.id),
            body: { ...bookingItemBody },
        });
    };

    const showConfirmChangeReceipt = (bookingItemBody: IUpdateRoomBookingItemBody) => {
        showConfirm({
            okText: t('roomBooking.detail.confirmUpdateReceipt.updateButton'),
            cancelText: t('roomBooking.detail.confirmUpdateReceipt.notUpdateButton'),
            title: t('roomBooking.detail.confirmUpdateReceipt.confirmTitle'),
            onOk: async () => {
                await checkPriceAndUpdateBooking({
                    ...bookingItemBody,
                    isUpdateReceipt: true,
                });
                setShowConfirm(false);
            },
            onCancel: async () => {
                await checkPriceAndUpdateBooking({
                    ...bookingItemBody,
                    isUpdateReceipt: false,
                });
                setShowConfirm(false);
            },
        });
    };

    const canNotEditField = (status: RoomBookingItemBookingStatus[]) => {
        // if ADMIN user, there should be no disabled fields and buttons
        if (isAdmin) return false;

        return status.includes(bookingItem.bookingStatus as RoomBookingItemBookingStatus);
    };

    const addNewGuest = async (representativeGuestName: string) => {
        const oldRepresentativeGuestId = selectedRoomBookingDetail!.representativeGuestId;
        const data = generateUpdateBookingData(selectedRoomBookingDetail!);
        const newGuest = {
            yomigana: representativeGuestName,
            fullName: null,
            emailAddress: null,
            mobilePhoneNumber: null,
            birthday: null,
            gender: null,
        };
        data.guests.push(newGuest);
        const response = await roomBookingService.updateBooking(
            selectedRoomBookingDetail!.id,
            data,
        );
        if (response?.success) {
            const resp = await roomBookingService.getDetail(
                selectedRoomBookingDetail!.id,
            );
            if (resp?.success) {
                const representativeGuest = resp.data?.guests?.find(
                    (guest) => guest.yomigana === representativeGuestName,
                );
                return representativeGuest?.id ?? oldRepresentativeGuestId;
            }
        }
        return oldRepresentativeGuestId;
    };

    const submit = () => {
        // When checkout and cancel, cannot update room booking item
        if (
            canNotEditField([
                RoomBookingItemBookingStatus.CHECKED_OUT,
                RoomBookingItemBookingStatus.CANCELLED,
            ])
        )
            return;

        handleSubmit(async (dataForm) => {
            if (isSubmitting.current) return;
            isSubmitting.current = true;
            const {
                representativeGuestName,
                isRepresentativeRoom,
                checkInTime,
                checkOutTime,
                roomId,
                roomTypeId,
                planId,
                bookingStatus,
                numberOfAdults,
                numberOfMale,
                numberOfFemale,
                numberOfOtherGenderGuest,
                dateOfStayPeriod,
            } = dataForm;

            if (bookingItem.isDayUse && dateOfStayPeriod && checkInTime && checkOutTime) {
                const validIn24H = validateDayUseTimeIn24H(
                    [parseDate(dateOfStayPeriod[0]), parseDate(dateOfStayPeriod[1])],
                    [checkInTime, checkOutTime],
                );
                if (!validIn24H) {
                    setError('dateOfStayPeriod', {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.time24hPeriodError'),
                    });
                    isSubmitting.current = false;
                    return;
                }
            }

            if (!bookingItem.isDayUse && dateOfStayPeriod) {
                const validStayingDate = validateStayingDate([
                    parseDate(dateOfStayPeriod[0]),
                    parseDate(dateOfStayPeriod[1]),
                ]);

                if (!validStayingDate) {
                    setError('dateOfStayPeriod', {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.datePeriodError'),
                    });
                    isSubmitting.current = false;
                    return;
                }
            }
            // check change room booking item status
            if (
                bookingStatus !== bookingItem.bookingStatus &&
                !ChangingBookingStatusesMap[bookingItem.bookingStatus]?.includes(
                    bookingStatus as RoomBookingItemBookingStatus,
                )
            ) {
                setError(
                    'bookingStatus',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.detail.message.cannotChangeStatus', {
                            status: t(`roomBooking.page.bookingStatus.${bookingStatus}`),
                        }),
                    },
                    { shouldFocus: true },
                );
                isSubmitting.current = false;
                return;
            }
            if (
                !validateGenderBreakdownOfGuest({
                    numberOfAdults,
                    numberOfMale,
                    numberOfFemale,
                    numberOfOtherGenderGuest,
                })
            ) {
                setError(
                    'numberOfAdults',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t('roomBooking.form.message.genderBreakdownError'),
                    },
                    { shouldFocus: true },
                );
                isSubmitting.current = false;
                return;
            }
            const _children: IChildBookingAmounts[] = [];

            forEach(dataForm.children, (val, index: number) => {
                const child = bookingItem.roomBookingItemChildrenTypes?.find((child) => {
                    return child.childrenTypeId === children[index].id;
                });
                _children.push({
                    id: child?.id || undefined,
                    quantity: Number(val),
                    childrenTypeId: children[index].id,
                });
            });

            const representativeGuest = selectedRoomBookingDetail?.guests?.find(
                (guest) => guest.yomigana === representativeGuestName,
            );
            let representativeGuestId = representativeGuest?.id ?? null;

            // if new guest, save representativeGuestName to guest list, then get the ID to save as representativeGuestId
            if (!representativeGuestId) {
                representativeGuestId = await addNewGuest(representativeGuestName);
            }

            let bookingItemBody: IUpdateRoomBookingItemBody = {
                roomId,
                bookingStatus,
                representativeGuestId: representativeGuestId || null,
                isRepresentativeRoom,
                checkOutTime: parseDate(checkOutTime)?.fmHHmm(),
                endDateOfStay: parseDate(dateOfStayPeriod[1])?.fmYYYYMMDDHHmmss('-'),
                isUpdateReceipt: true,
            };

            if (bookingItem.bookingStatus === RoomBookingItemBookingStatus.NOT_ARRIVED) {
                bookingItemBody = {
                    ...bookingItemBody,
                    planId,
                    roomTypeId,
                    numberOfAdults: Number(numberOfAdults),
                    numberOfMale: Number(numberOfMale) || 0,
                    numberOfFemale: Number(numberOfFemale) || 0,
                    numberOfOtherGenderGuest: Number(numberOfOtherGenderGuest) || 0,
                    checkInTime: parseDate(checkInTime)?.fmHHmm(),
                    startDateOfStay: parseDate(dateOfStayPeriod[0])?.fmYYYYMMDDHHmmss(
                        '-',
                    ),
                    roomBookingItemChildrenTypes: _children,
                };
            }

            const targetRoomBookingItems = selectedRoomBookingDetail?.roomBookingItems
                .filter((roomBookingItem) => {
                    if (bookingItem.parentRoomBookingItemId) {
                        return (
                            roomBookingItem.id === bookingItem.parentRoomBookingItemId ||
                            roomBookingItem.parentRoomBookingItemId ===
                                bookingItem.parentRoomBookingItemId
                        );
                    } else {
                        return (
                            roomBookingItem.id === bookingItem.id ||
                            roomBookingItem.parentRoomBookingItemId === bookingItem.id
                        );
                    }
                })
                .map((roomBookingItem) =>
                    roomBookingItem.id === bookingItem.id
                        ? ({
                              id: Number(bookingItem.id),
                              parentRoomBookingItemId:
                                  bookingItem.parentRoomBookingItemId,
                              ...bookingItemBody,
                          } as IRoomBookingItem)
                        : roomBookingItem,
                );

            const parentRoomBookingItem = targetRoomBookingItems?.find(
                (roomBookingItem) =>
                    Number(roomBookingItem.id) === Number(bookingItem.id) ||
                    Number(roomBookingItem.id) === bookingItem.parentRoomBookingItemId,
            );

            const childRoomBookingItems = targetRoomBookingItems?.filter(
                (roomBookingItem) =>
                    roomBookingItem.parentRoomBookingItemId ===
                    Number(parentRoomBookingItem?.id),
            );

            // child booking items CI date cannot be before parent booking item CO date
            if (
                childRoomBookingItems?.some((childRoomBookingItem) =>
                    parseDate(parentRoomBookingItem?.endDateOfStay).isAfter(
                        parseDate(childRoomBookingItem.startDateOfStay),
                    ),
                )
            ) {
                setError(
                    'dateOfStayPeriod',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t(
                            'roomBooking.form.message.parentBookingItemShouldComeFirstError',
                        ),
                    },
                    { shouldFocus: true },
                );
                isSubmitting.current = false;

                return;
            }

            // there should not be no overlapped in CI/CO dates in parent/children booking items
            if (
                targetRoomBookingItems &&
                checkHasOverlappingDatesInRoomBookingItems(targetRoomBookingItems)
            ) {
                setError(
                    'dateOfStayPeriod',
                    {
                        type: ErrorMessageType.MANUAL,
                        message: t(
                            'roomBooking.form.message.parentChildrenBookingItemsOverlappingError',
                        ),
                    },
                    { shouldFocus: true },
                );
                isSubmitting.current = false;

                return;
            }

            if (isChangeReceiptPrice(bookingItemBody, bookingItem)) {
                await checkPriceAndUpdateBooking(bookingItemBody);
            } else {
                showConfirmChangeReceipt(bookingItemBody);
                setShowConfirm(true);
            }
        })();
    };

    const _updateRoomBookingItem = useCallback(
        async (roomBookingItem: IUpdateRoomBookingItem) => {
            const response = await dispatch(updateRoomBookingItem(roomBookingItem));
            if (updateRoomBookingItem.fulfilled.match(response)) {
                if (response.payload?.success) {
                    notification.success({
                        message: t('roomBooking.detail.message.updateSuccess'),
                    });
                    const updateBooking = response.payload?.data;
                    const selectedRoomType = roomTypeDropdown.find(
                        (option) => option.id === updateBooking.roomTypeId,
                    );
                    onUpdateSuccess?.({
                        ...updateBooking,
                        totalAmount: bookingPrice.current,
                        roomType: selectedRoomType as IRoomType,
                    });
                    const isReload = !onUpdateSuccess;
                    closePopover(false, isReload);
                } else {
                    (response.payload?.errors || []).forEach((error) => {
                        if (
                            error.key === 'startDateOfStay' ||
                            error.key === 'endDateOfStay'
                        ) {
                            setError('dateOfStayPeriod', {
                                type: ErrorMessageType.MANUAL,
                                message: error.message,
                            });
                        }
                        setError(error.key, {
                            type: ErrorMessageType.MANUAL,
                            message: error.message,
                        });
                    });
                    notification.error({
                        message: response.payload?.message || '',
                    });
                }
            }
            isSubmitting.current = false;
        },
        [],
    );

    const fetchRoomDropDown = debounce((roomBookingStayPeriod: string[]) => {
        dispatch(
            getListForDropDown({
                roomBookingItemId: Number(bookingItem.id) || undefined,
                roomBookingStayPeriod,
                isDayUse: bookingItem.isDayUse,
            }),
        );
    }, 1000);

    useEffect(() => {
        reset({
            representativeGuestId: bookingItem?.representativeGuestId || undefined,
            representativeGuestName:
                bookingItem?.representativeGuest?.yomigana || undefined,
            bookingStatus: bookingItem.bookingStatus,
            roomId: bookingItem?.room?.id,
            isRepresentativeRoom: bookingItem.isRepresentativeRoom,
            roomTypeId: bookingItem.roomType?.id,
            planId: bookingItem.plan?.id,
            dateOfStayPeriod: [
                parseDate(bookingItem.startDateOfStay),
                parseDate(bookingItem.endDateOfStay),
            ],
            checkInTime: bookingItem.checkInTime
                ? parseTime(bookingItem.checkInTime)
                : null,
            checkOutTime: bookingItem.checkOutTime
                ? parseTime(bookingItem.checkOutTime)
                : null,
            numberOfAdults: bookingItem.numberOfAdults,
            numberOfMale: bookingItem.numberOfMale || null,
            numberOfFemale: bookingItem.numberOfFemale || null,
            numberOfOtherGenderGuest: bookingItem.numberOfOtherGenderGuest || null,
        });
        fetchRoomDropDown([
            `${parseDate(bookingItem.startDateOfStay).fmYYYYMMDD('-')} ${parseTime(
                bookingItem.checkInTime,
            ).fmHHmmss()}`,
            `${parseDate(bookingItem.endDateOfStay).fmYYYYMMDD('-')} ${parseTime(
                bookingItem.checkOutTime,
            ).fmHHmmss()}`,
        ]);
    }, [bookingItem]);

    useEffect(() => {
        const selectedRoomType = roomTypeDropdown.find((roomType) => {
            return roomType.id === bookingItem.roomType?.id;
        });
        setValue('standardCapacity', selectedRoomType?.standardCapacity || 0);
    }, [bookingItem, roomTypeDropdown, setValue]);

    return (
        <Card
            className="room-booking-item-card"
            actions={[
                <Button key="close" onClick={() => closePopover(false, false)}>
                    {t('common.buttonCancelText')}
                </Button>,
                <Button
                    key="submit"
                    onClick={submit}
                    className="btn-submit"
                    type="primary"
                    // save button is disabled when status is CHECKED_OUT or CANCELLED
                    // only for ADMIN user, the button is always enabled regardless the status
                    disabled={canNotEditField([
                        RoomBookingItemBookingStatus.CHECKED_OUT,
                        RoomBookingItemBookingStatus.CANCELLED,
                    ])}
                    loading={formBusy || isCheckingPlan}
                >
                    {t('common.buttonSaveText')}
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <CardHeader
                    control={control}
                    // customer name dropdown field is disabled when status is CHECKED_OUT or CANCELLED
                    // only for ADMIN user, the field is always enabled regardless the status
                    disabled={canNotEditField([
                        RoomBookingItemBookingStatus.CHECKED_OUT,
                        RoomBookingItemBookingStatus.CANCELLED,
                    ])}
                />
                <Divider className="divider" />
                <CardContent
                    bookingItem={bookingItem}
                    isSingleBooking={isSingleBooking}
                    fetchRoomDropDown={fetchRoomDropDown}
                    isFromTll={isFromTll}
                    control={control}
                    isFrozen={isFrozen}
                    setValue={setValue}
                    getValues={getValues}
                    setError={setError}
                    closePopover={closePopover}
                    onChangeChildrenList={(value) => setChildrenList(value)}
                    onUpdateStatusSuccess={onUpdateStatusSuccess}
                />
            </Form>
        </Card>
    );
}

export default RoomBookingItemForm;
